import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import training from '../assets/images/training.jpg';

const Education = props => (
  <Layout>
    <Helmet>
      <title>Education And Training</title>
      <meta name="description" content="Education And Training" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Education And Training</h1>
          </header>
          <span className="image main">
            <img src={training} alt="Education And Training" />
          </span>
          <p>
            <strong>
              <u>Years Of Experience</u>
            </strong>
          </p>
          <p>
            I have trained over 150 clients over the course of 11 years. All
            clients have come to me from different backgrounds, such as coming
            out of physical therapy, dealing with a disease like cancer or
            multiple sclerosis, wanting weight-loss and muscle gain, or trying
            to become the best athlete they can be. Thankfully I have had the
            education and background to help all of them! I specialize in
            creating a specific workout built to burn your fat, build your
            muscle, and achieve whatever goal you have in mind.
          </p>
          <p>
            I have been a personal trainer, coordinator, director, and most
            recently regional director for corporate companies, before realizing
            I can bring so much more value to each of you on my own. It’s in my
            heart to give you the best plan possible and at a value you can
            afford.
          </p>
          <p>
            <strong>
              <u>Personal Training</u>
            </strong>
          </p>
          <ul>
            <li>
              NASM - CPT: Certified Personal Trainer through the National
              Academy of Sports Medicine
            </li>
            <li>American Red Cross - CPR/AED Certified</li>
            <li>
              Bachelor’s Degree in Human Biology, Minor in Exercise Science
            </li>
            <li>11 years of Experience</li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default Education;
